<template>
  <div class="my_lesson_content_sty">
    <title class="my_lesson_title_sty">我的课程</title>
    <div class="filter_content_sty">
      <div>
        <el-select v-model="classId" placeholder="请选择">
          <el-option
            v-for="item in classList"
            :key="item.classId"
            :label="item.className"
            :value="item.classId"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="params.categoryId" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.categoryId"
            :label="item.name"
            :value="item.categoryId"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="lesson_main_content_sty">
      <oneLesson
        v-for="i in memberOrderList"
        :key="i.courseId"
        :lessonInfo="i"
        width="208"
        height="132"
        class="lesson_sty"
        @click.native="goDetail(i)"
      />
    </div>
    <div class="turn_page_sty">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :page-size="params.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
    <emptyStatus
      v-show="isEmpty"
      :img="emptyImg"
      :haveButton="true"
      @btnClick="goStudy"
    />
  </div>
</template>

<script>
import oneLesson from "../oneLesson";
import { getMenberOrder } from "@/api/curriculum.js";
import { myClass, myClassCourse } from "@/api/class";
import emptyStatus from "../emptyStatus";
export default {
  name: "myLesson",
  components: {
    oneLesson,
    emptyStatus,
  },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 8,
        categoryId: null,
      },
      classId: null,
      memberOrderList: [],
      total: 0,
      classType: "已购课程",
      classList: [],
      isEmpty: false,
      emptyImg: require("@/assets/img/emptyIcon/wukechen@2x.png"),
    };
  },
  watch: {
    classId: function () {
      this.params.pageNum = 1;
      this.getList()
    },
    "params.categoryId": function () {
      this.params.pageNum = 1;
      this.getList()
    },
  },
  computed: {
    categoryList: function () {
      let list = this.$store.state.classificationOrgin || [];
      list = list.filter((e) => {
        return e.level === 1;
      });
      list = [{ level: 1, name: "全部分类", categoryId: null }, ...list];
      return list;
    },
  },
  created() {
    this.getList();
    this.myClass();
  },
  methods: {
    getList() {
      if (this.classId) {
        this.myClassCourse();
      } else {
        this.getMenberOrder();
      }
    },
    getMenberOrder() {
      let that = this;
      this.$loading.show();
      getMenberOrder(this.params).then((json) => {
        that.$loading.hide();
        const { code, rows, msg, total } = json;
        if (code === 200) {
          this.memberOrderList = rows;
          this.isEmpty = false;
          if (this.memberOrderList.length == 0) {
            this.isEmpty = true;
          } else {
            false;
          }
          this.total = total;
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    myClassCourse() {
      this.$loading.show();
      let that = this;
      myClassCourse(this.params, this.classId).then((json) => {
        that.$loading.hide();
        const { code, rows, msg, total } = json;
        if (code === 200) {
          this.memberOrderList = rows;
          this.isEmpty = false;
          if (this.memberOrderList.length == 0) {
            this.isEmpty = true;
          } else {
            false;
          }
          this.total = total;
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    // 获取我的班级
    myClass() {
      myClass().then((json) => {
        const { code, rows, msg } = json;
        if (code === 200) {
          this.classList = [{ classId: null, className: "已购课程" }, ...rows];
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getList();
    },
    // 去详情页面
    goDetail(item) {
      let id = item.courseId;
      this.$router.push({ path: `/courseDetail?courseId=${id}` });
    },
    goStudy() {
      this.$router.push("/allCourses");
    },
  },
};
</script>

<style lang="less" scoped>
.my_lesson_content_sty {
  width: 100%;
  text-align: left;
  height: 100%;
  position: relative;
  min-height: 748px;
  .my_lesson_title_sty {
    height: 60px;
    line-height: 60px;
    display: block;
    border-bottom: 1px solid #eeeeee;
    padding-left: 29px;
    box-sizing: border-box;
    color: #333;
    font-size: 18px;
    font-weight: 500;
  }
  .filter_content_sty {
    padding: 22px 30px;
    padding-bottom: 0;
    box-sizing: border-box;
    & > div {
      display: inline-block;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      &:last-child {
        margin-left: 45px;
      }
    }
  }
}
.lesson_main_content_sty {
  padding: 0 30px;
  box-sizing: border-box;
}
.lesson_sty {
  margin-right: 24px;
  margin-top: 20px;
  &:nth-child(4n) {
    margin-right: 0 !important;
  }
}
.turn_page_sty {
  text-align: right;
  position: absolute;
  bottom: 26px;
  right: 30px;
}
</style>